import { Decimal } from 'decimal.js';
/**
 * Rounds the decimal part of a number to two decimal places without rounding up.
 *
 * @param {number} number - The input number to be rounded.
 * @returns {number} The number with the decimal part truncated to two decimal places.
 */
export function roundDownDecimals(number, precision = 2) {
  // console.log(number)
  const decimalBase = new Decimal(number);
  const decimalNumber = decimalBase
    .toDP(precision, Decimal.ROUND_DOWN)
    .toNumber();

  // console.log(decimalNumber)
  return decimalNumber;
}
