export function transformTitle(type) {
  if (type === 'air_quality_co2') {
    return 'CO2';
  }

  return (
    type
      // Replace underscores and hyphens with spaces
      .replace(/[_-]/g, ' ')
      // Split the string by spaces
      .split(' ')
      // Capitalize each word, and handle special cases like "CO2"
      .map((word) => {
        const sensorUpperNames = ['CO', 'O2', 'NO2', 'SO2', 'TPM', 'CO2'];
        if (sensorUpperNames.includes(word.toUpperCase())) {
          return word.toUpperCase(); // Ensure "CO2, CO, O2, NO2, SO2, TPM" remains capitalized
        } else {
          return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        }
      })
      // Join the words back into a string
      .join(' ')
  );
}
