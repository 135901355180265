import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { environment } from '../../environments/environment';
import { TokenService } from '../shared/services/token.service';

/**
 * Prefixes all requests not starting with `http[s]` with `environment.serverUrl`.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private credentials: TokenService,
    private authService: AuthService,
    private router: Router
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.credentials.getToken()
      ? this.credentials.getToken()
      : '';

    if (
      token &&
      token.length &&
      (request.url.search(environment.settings.avlApiHost) > -1 ||
        request.url.search(environment.settings.sensewareApiHost) > -1)
    ) {
      request = request.clone({
        headers: request.headers
          .set('Accept-Language', 'en')
          .set('Content-Type', 'application/json; charset=utf-8')
          .set('Accept', 'application/json')
          .set('Authorization', `Bearer ${token}`)
          .set('Access-Control-Allow-Origin', '*'),
        // params: request.params.set('token', `${token}`),
      });
    } else {
      request = request.clone({
        headers: request.headers
          // .set('Accept-Language', 'en')
          .set('Access-Control-Allow-Origin', '*')
          .set('Content-Type', 'application/json; charset=utf-8')
          .set('Accept', 'application/json'),
      });
    }
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        // let errorMsg = '';
        // if (error.error instanceof ErrorEvent) {
        //   console.log('this is client side error');
        //   errorMsg = `Error: ${error.error.message}`;
        // }
        // else {
        //   console.log('this is server side error');
        //   errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
        // }
        if (!this.router.url.includes('/login')) {
          if (error.error instanceof ErrorEvent) {
            // console.log('this is client side error');
          } else if (error.status == 401) {
            this.authService.logout();
            this.router.navigate(['/auth/access-denied/401']);

            // location.href = `${environment.BE_serverUrl}login`;
          } else if (error.status == 403) {
            this.authService.logout();
            this.router.navigate(['/auth/avl-login']);

            // location.href = `${environment.BE_serverUrl}login`;
          } else if (error.status == 423) {
            this.router.navigate(['/auth/expiry-password/423']);
          } else if (error.status == 500) {
            // this.router.navigate(['errors'], {
            // 	state: { status: 500 },
            // });
            // this.toastrService.makeToast(
            // 	'error',
            // 	'Error',
            // 	'Something went wrong',
            // 	{
            // 		positionClass: ToasterPosition.topRight,
            // 		toastClass: 'oneLine',
            // 		closeButton: true,
            // 	}
            // );
          } else if (error.status === 0) {
            // this.router.navigate(['errors'], {
            // 	state: { status: 'offline' },
            // });
            // this.toastrService.makeToast(
            // 	'error',
            // 	'Error',
            // 	'Something went wrong',
            // 	{
            // 		positionClass: ToasterPosition.topRight,
            // 		toastClass: 'oneLine',
            // 		closeButton: true,
            // 	}
            // );
          } else {
            // console.log('something went wrong');
          }
        }
        return throwError(error);
      })
    );
  }

  // return next.handle(request);
}
