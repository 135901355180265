import { UnitLastUpdate } from '../interfaces/unit-last-update';
import { Injectable } from '@angular/core';
import { roundDownDecimals } from '../helpers/roundDownDecimals';

@Injectable({
  providedIn: 'root',
})
export class CalibrateService {
  getSensorValue(
    sensor,
    options?: { lastUpdate?: UnitLastUpdate; value?: number }
  ): any {
    let result = {};
    result['value'] = null;
    result['value_full'] = '';

    // console.log(sensor, options)
    // get param value
    if (!sensor['param']) return result;
    let param_value;
    if (options?.lastUpdate?.prms[sensor['param']])
      param_value = options?.lastUpdate?.prms[sensor['param']];
    // sensor[2] = pin that contain sensor => di1 , di3 , di2 , param72 etc
    else if (options?.lastUpdate?.chPrams[sensor['param']])
      param_value = options.lastUpdate.chPrams[sensor['param']]['v'];
    else if (!isNaN(options?.value)) {
      param_value = options.value;
    } else return result;

    // console.log('param_value', param_value);
    //check if param one of params and return its value
    if (param_value == undefined || param_value == '') {
      param_value = 0;
    }
    // console.log(param_value);

    let main_param_value = param_value;

    // sensor[2] = pin that contain sensor => di1 , di3 , di2 , param72 etc
    // sensor[3] = type of sensor
    // sensor[4] = "ON"
    // sensor[5] = "OFF"
    // sensor[7] = Lowest value
    // sensor[8] = Highest value
    // sensor[9] = Formula => ex. mul|1.2
    // sensor[11]= icon
    // sensor[12]= serial
    // sensor[13]= model

    /* ==============================
         check type of sensor
         ============================== */
    // Logic sensor
    // sensor['resultType'] == sensor[3]
    if (sensor['resultType'] == 'logic') {
      if (param_value == 1) {
        result['value'] = param_value;
        result['value_full'] = sensor.text_1; // sensor[4] == "ON"
      } else {
        result['value'] = param_value;
        result['value_full'] = sensor.text_0; // sensor[5] == "OFF"
      }
    }
    // value or value_low_high sensors
    else if (
      sensor['resultType'] == 'value' ||
      sensor['resultType'] == 'value_low_high'
    ) {
      param_value = parseFloat(param_value);

      //formula calculations
      if (sensor['formula'] != '') {
        let formula = sensor['formula']; // formula like + - ...etc
        let formulaValue = parseFloat(sensor['formulaValue']); // value of that formula

        if (formula === '+') {
          param_value = param_value + formulaValue;
        }

        if (formula === '-') {
          param_value = param_value - formulaValue;
        }

        if (formula === '*') {
          param_value = param_value * formulaValue;
        }

        if (formula === '/') {
          param_value = param_value / formulaValue;
        }
      }

      // calibration
      let out_of_cal = true;
      let calibration = sensor['calibration'];

      // function to get calibration Y value
      let calGetY = function (x) {
        let result = 0;
        for (let j = 0; j < calibration.length; j++) {
          if (calibration[j]['x'] == x) {
            result = parseFloat(calibration[j]['y']);
          }
        }
        return result;
      };

      if (calibration && calibration.length >= 2) {
        // put all X values to separate array
        let x_arr = new Array();
        for (let i = 0; i < calibration.length; i++) {
          x_arr.push(parseFloat(calibration[i]['x']));
        }

        //function sortNumber(a, b) {return a - b; }
        //instead in sortNumber(a, b) function
        x_arr.sort((a, b) => a - b);

        // loop and check if in cal
        for (let i = 0; i < x_arr.length; i++) {
          let x_low = x_arr[i];
          let x_high = x_arr[i + 1];

          if (param_value >= x_low && param_value <= x_high) {
            // get Y low and high
            let y_low = calGetY(x_low);
            let y_high = calGetY(x_high);

            // get coeficient
            let a = param_value - x_low;
            let b = x_high - x_low;

            let coef = a / b;

            let c = y_high - y_low;
            coef = c * coef;

            param_value = y_low + coef;

            out_of_cal = false;

            break;
          }
        }

        if (out_of_cal) {
          // check if lower than cal
          let x_low = x_arr[0];

          if (param_value < x_low) {
            param_value = calGetY(x_low);
          }

          // check if higher than cal
          let x_high = x_arr.slice(-1)[0];

          if (param_value > x_high) {
            param_value = calGetY(x_high);
            //weight sensor
            let calibrationCount = calibration.length;
            let a =
              parseFloat(calibration[calibrationCount - 1]['x']) -
              parseFloat(calibration[calibrationCount - 2]['x']);
            let b =
              parseFloat(calibration[calibrationCount - 1]['y']) -
              parseFloat(calibration[calibrationCount - 2]['y']);
            let dif = b / a;
            param_value =
              eval(param_value) +
              eval(
                String(
                  dif *
                    (main_param_value - calibration[calibrationCount - 1]['x'])
                )
              );
            //weight sensor
          }
        }
      }

      // param_value = Math.round(param_value * 100) / 100;
      param_value =
        !isNaN(param_value) && !isNaN(roundDownDecimals(param_value))
          ? roundDownDecimals(param_value)
          : '';
      // console.log(param_value);

      result['value'] = param_value;
      result['value_full'] = param_value + ' ' + sensor['measurementUnit']; //sensor[6] = Units of measurement
    }
    // string value
    else if (sensor['resultType'] == 'string') {
      result['value'] = param_value;
      result['value_full'] = param_value;
    }
    // hexString value
    else if (sensor['resultType'] == 'hexString') {
      let newValue;
      result['value'] = param_value;
      const isDecimal = isNaN(param_value);
      if (!isDecimal) {
        newValue = parseInt(param_value);
        result['value_full'] = newValue.toString(16).toUpperCase();
      } else {
        result['value_full'] = param_value.toString(16).toUpperCase();
      }
    }
    // percentage value
    else if (sensor['resultType'] == 'percentage') {
      param_value = parseFloat(param_value);
      sensor['lowVal'] = parseFloat(sensor['lowVal']); //Lowest value
      sensor['highVal'] = parseFloat(sensor['highVal']); //Highest value

      if (param_value > sensor['lowVal'] && param_value < sensor['highVal']) {
        let a = param_value - sensor['lowVal'];
        let b = sensor['highVal'] - sensor['lowVal'];

        result['value'] = Math.round((a / b) * 100);
      } else if (param_value <= sensor['lowVal']) {
        result['value'] = 0;
      } else if (param_value >= sensor['highVal']) {
        result['value'] = 100;
      }

      result['value_full'] = result['value'] + ' ' + sensor['measurementUnit'];
    }
    // code value
    else if (sensor['resultType'] == 'codeValue') {
      let val = '';
      for (let j = 0; j < sensor['codeValue'].length; j++) {
        if (sensor['codeValue'][j]['x'] == param_value) {
          val = sensor['codeValue'][j]['y'];
          break;
        }
      }

      result['value'] = val;
      result['value_full'] = val;
    }

    // console.log('param_value', param_value);
    // console.log('result', result);
    // console.log('sensor', sensor);
    return result;
  }
}
